<template>
  <div />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import ValidationError from 'chimera/all/functions/ValidationError'
import ValidationRules from 'chimera/all/functions/ValidationRules'
import validatesWithApi from 'chimera/all/mixins/validatesWithApi'
import { debounce } from 'chimera/all/plugins/global.functions'
import { mapState } from 'vuex'

export default {
  mixins: [validatesWithApi],

  props: {
    field: {
      type: String,
      default: 'unknown',
    },

    /**
     * Disable autofocus by default
     */
    autofocus: {
      type: Boolean,
      default: false,
    },

    /**
     * Disable autocomplete by default
     */
    autocomplete: {
      type: String,
      default: 'no-fill',
    },

    /**
     * Used to render choices (for example a ButtonGroupFormPart)
     */
    choices: {
      type: Array,
      default: () => [],
    },

    /**
     * When true the value of the form part will be set with the related data from the store.
     */
    isRemembered: {
      type: Boolean,
      default: true,
    },

    /**
     * Is optional
     */
    isOptional: {
      type: Boolean,
      default: false,
    },

    /**
     * Is validated with api
     */
    isApiValidated: {
      type: Boolean,
      default: true,
    },

    /**
     * Initialize value with store key
     */
    initWithStoreKey: {
      type: String,
      default: undefined,
    },

    /**
     * Initialize value with variable.
     */
    initWithValue: {
      type: null,
      default: undefined,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      invalidValidationCount: 0,
    }
  },

  computed: {
    ...mapState({
      storeValue: (state) => state.lead.data[this.field],
    }),
  },

  /**
   * On create
   */
  created() {
    // Give ability to debounce the validation
    this.debouncedValidateFn = debounce((value) => {
      this.validate(value)
    }, 500)
  },

  /**
   * On mount
   */
  mounted() {
    this.initialise()
  },

  methods: {
    /**
     * When being init we use isOptional to determine if it's valid by default.
     */
    initialise() {
      if (this.isOptional) {
        this.emitResult(true)
      }

      // Determine if and how the value should be initialised.
      let valueForField

      // First we try to look up a previously validated and stored value for this specific field. This means
      // the consumer already entered specific input for this field and should be prioritized before other init methods.
      if (this.isRemembered && this.getValueForFieldFromStore(this.field)) {
        valueForField = this.getValueForFieldFromStore(this.field)

        // If the field has choices, filter out all remembered values that are not present in the given choices.
        // This prevents an issue with fields using the same key, but have different answers depending on the flow.
        const hasChoices = this.choices.length > 0
        const valueForFieldIsArray = Array.isArray(valueForField)
        if (hasChoices && valueForFieldIsArray) {
          valueForField = this.getValueForChoices(valueForField, this.choices)
        }
      }

      // Second, see if we want to init this field with a value from the store that might belong to another field.
      // In that case we retrieve the value. This can be the case with postal/address-postal f.e or situations like
      // home address / delivery address prefills.
      if (
        !valueForField &&
        this.initWithStoreKey &&
        this.getValueForFieldFromStore(this.initWithStoreKey)
      ) {
        valueForField = this.getValueForFieldFromStore(this.initWithStoreKey)
      }

      // Finally we might just receive a as-is value, let's go with it.
      const shouldUseInitWithValue =
        this.isEmptyValue(valueForField) &&
        !this.isEmptyValue(this.initWithValue)
      if (shouldUseInitWithValue) {
        valueForField = this.initWithValue
      }

      // When the value isn't empty, let's set it.
      if (!this.isEmptyValue(valueForField)) {
        this.setValue(valueForField)
      }
    },

    /**
     * Checks whether a value is empty.
     *
     * @param value
     * @returns {boolean}
     */
    isEmptyValue(value) {
      // Early out on undefined, as that's nothing.
      if (typeof value === 'undefined') {
        return true
      }

      // Objects require key counting for length.
      if (typeof value === 'object') {
        return Object.keys(value).length < 1
      }

      // Objects require key counting for length.
      if (typeof value === 'string') {
        value = value.trim()
      }

      return value.length < 1
    },

    /**
     * @param {boolean} isValid
     */
    emitResult(isValid) {
      this.isValid = isValid

      this.$emit('result', {
        field: this.field,
        isValid,
        value: this.value,
        errors: this.errors,
        isOptional: this.optional,
        invalidValidationCount: this.invalidValidationCount,
      })
    },

    /**
     * Validate current field with value
     *
     * @param {any} value
     * @returns {void|Promise<Response>}
     */
    validate(value = this.value) {
      // Field is required, but value is empty and is optional.
      const valueIsEmpty = this.isEmptyValue(value)
      if (valueIsEmpty && this.isOptional) {
        this.onValid(value)
        return
      }

      // Validate forms with front-end validation rules
      const rules = this.validationRules()
      const errorKeys = ValidationRules.evaluate(rules, value)
      if (errorKeys.length > 0) {
        this.onInvalid(value, errorKeys)
        return
      }

      // No remaining validation errors and no API validation
      if (!this.isApiValidated) {
        this.onValid(value)
        return
      }

      // Validate with API
      return this.validateField(this.field, value)
    },

    /**
     *
     * @param value
     */
    debouncedValidate(value) {
      this.debouncedValidateFn(value)
    },

    /**
     * Reset field.
     */
    reset() {
      // Since reset is called on value change, we should emit a change event.
      this.$emit('change', {
        field: this.field,
        value: this.value,
      })
      this.emitResult(false)
      this.unsetValueInStore()
    },

    /**
     * Default implementation of packing the value.
     * Can be used to modify the value that is stored in the store for field.
     *
     * @param {*} value
     * @returns {*}
     */
    packValue(value) {
      return value
    },

    /**
     * Default implementation of unpacking the value.
     * Can be used to modify the value that is stored in the store for field.
     *
     * @param {*} value
     * @returns {*}
     */
    unpackValue(value) {
      return value
    },

    /**
     * Deletes the field value from the store.
     */
    unsetValueInStore() {
      this.$store.dispatch('lead/rm', this.field)
    },

    /**
     * Returns value if value in store is found for field.
     *
     * @param {string} field
     * @param {object|undefined} fallback
     * @returns {*}
     */
    getValueForFieldFromStore(field = this.field, fallback = undefined) {
      const value = this.$store.getters['lead/getData'](field, fallback)
      return this.unpackValue(value)
    },

    /**
     * Set the field value in the store.
     *
     * @param {any} rawValue
     * @param {string|undefined} origin
     */
    setValueInStore(rawValue, origin = this.getParentFormPartName()) {
      const value = this.packValue(rawValue)
      this.$store.dispatch('lead/add', {
        key: this.field,
        value,
        origin,
      })
    },

    /**
     * This hook is called at the start of the onValid method and allows you to perform additional
     * code without having to override the onValid functionality.
     *
     * @param {*} value
     */
    beforeOnValid(value) {},

    /**
     * @param {*} value
     */
    onValid(value) {
      this.beforeOnValid(value)
      this.setInvalidValidationCount(0)
      this.errors = []
      this.setValueInStore(value)
      this.emitResult(true)
      this.$eventBus.emitFormFieldValidEvent(this.field, value)
      this.afterOnValid(value)
    },

    /**
     * This hook is called at the end of the onValid method and allows you to perform additional
     * code without having to override the onValid functionality.
     *
     * @param {*} value
     */
    afterOnValid(value) {},

    /**
     * This hook is called at the start of the onInvalid method and allows you to perform additional
     * code without having to override the onInvalid functionality.
     *
     * @param {Array} mappedErrors
     * @param {Array} errorKeys
     */
    beforeOnInvalid(mappedErrors, errorKeys) {},

    /**
     * @param {any} value - The value that was invalid.
     * @param {Array} errorKeys - List of error keys.
     */
    onInvalid(value, errorKeys) {
      const mappedErrors = ValidationError.mapErrors(
        errorKeys,
        this.customErrorMap(),
      ).map((error) => this.$i18n.t(error))

      this.beforeOnInvalid(mappedErrors, errorKeys)
      this.setInvalidValidationCount(this.invalidValidationCount + 1)
      this.errors = mappedErrors
      this.emitResult(false)
      this.$eventBus.emitFormFieldErrorEvent(this.field, value, errorKeys)
      this.afterOnInvalid(mappedErrors, errorKeys)
    },

    /**
     * This hook is called at the end of the onValid method and allows you to perform additional
     * code without having to override the onValid functionality.
     *
     * @param {Array} mappedErrors
     * @param {Array} errorKeys
     */
    afterOnInvalid(mappedErrors, errorKeys) {},

    /**
     * This hook is called at the start of the onError method and allows you to perform additional
     * code without having to override the onError functionality.
     *
     * @param {*} value
     */
    beforeOnError(value) {},

    /**
     * On Api error
     *
     * @param value
     */
    onError(value) {
      this.beforeOnError(value)
      this.setValueInStore(value)
      this.emitResult(true)
      this.afterOnError(value)
    },

    /**
     * This hook is called at the end of the onError method and allows you to perform additional
     * code without having to override the onError functionality.
     *
     * @param {*} value
     */
    afterOnError(value) {},

    /**
     * @returns {Array}
     */
    validationRules() {
      return ValidationRules.getRules()
    },

    /**
     * Map for customized error messages
     *
     * @returns {*[]}
     */
    customErrorMap() {
      return []
    },

    /**
     * Directly set the value.
     *
     * @param {any} value
     */
    setValue(value) {
      if (value === this.value) {
        return
      }

      this.value = value
      this.reset()
      this.validate(value)
    },

    /**
     * Directly clear the value without validating.
     */
    clearValue() {
      this.value = undefined
      this.reset()
    },

    /**
     * Get parent form part name, excluding AbstractFormPart
     *
     * @returns {string | undefined}
     */
    getParentFormPartName() {
      let parent = this.$parent
      if (!parent) {
        return undefined
      }

      let name
      while (parent && !name) {
        const parentName = parent.$options.name
        if (parentName !== 'AbstractFormPart') {
          name = parentName
        }
        parent = parent.$parent
      }

      return name
    },

    /**
     * Get attribute of field
     *
     * @param {string} attribute
     * @returns {string | undefined}
     */
    getAttribute(attribute) {
      if (!this.hasAttribute(attribute)) {
        return undefined
      }

      return this.$attrs[attribute]
    },

    /**
     * Checks if attribute exists on field
     *
     * @param {string} attribute
     * @returns {boolean}
     */
    hasAttribute(attribute) {
      if (!this.$attrs) {
        return false
      }

      return attribute in this.$attrs
    },

    /**
     * @param {Array} valueForField
     * @param {Selectable[]} choices
     *
     * @returns {any | undefined}
     */
    getValueForChoices(valueForField, choices) {
      return valueForField.filter((selectable) => {
        const matchingValuesFromChoices = choices
          .map((choice) => choice.id)
          .filter((id) => id === selectable.id)
        return matchingValuesFromChoices.length > 0
      })
    },

    /**
     * @param {number} count
     */
    setInvalidValidationCount(count) {
      this.invalidValidationCount = count
    },
  },
}
</script>
